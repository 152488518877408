import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Product } from '../shared/interfaces/product.interface';
import { CompaniesService } from '../companies/companies.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  token: string;

  constructor(
    private httpClient: HttpClient,
    private companiesService: CompaniesService
  ) {}

  auth(company): Observable<{ token: string }> {
    this.companiesService.setCompanyCredencials(company);

    return this.httpClient.get<{ token: string }>(
      this.companiesService.url +
        '/auth?login=' +
        this.companiesService.login +
        '&password=' +
        this.companiesService.password +
        '&returnType=json'
    );
  }

  getProducts({ company }): Observable<Product[]> {
    this.companiesService.setCompanyCredencials(company);

    return this.auth(company).pipe(
      switchMap((auth) =>
        this.httpClient
          .get<{ Produtos: Product[] }>(
            this.companiesService.url +
              '/products?token=' +
              auth.token +
              '&returnType=json&enablePhoto=1'
          )
          .pipe(
            map((res) =>
              res.Produtos.filter(
                (el) => el.Produto.toLowerCase() !== 'institucional'
              )
            )
          )
      )
    );
  }

  async getParameters(company: string, parameter: string) {
    this.companiesService.setCompanyCredencials(company);

    let x = await this.auth(company).toPromise();

    return await this.httpClient
      .get<any[]>(
        this.companiesService.url +
          '/parameters?token=' +
          x.token +
          '&returnType=json&parameters=' +
          parameter
      )
      .toPromise();
  }

  async getCoordinator(company: string) {
    this.companiesService.setCompanyCredencials(company);

    let x = await this.auth(company).toPromise();

    return await this.httpClient
      .get<any[]>(
        this.companiesService.url +
          '/getusersinfo?token=' +
          x.token +
          '&returnType=json&profiles[]=4&fields=nome,email,id_usuario,foto,telefone'
      )
      .toPromise();
  }

  async getModule(company: string, module: string) {
    this.companiesService.setCompanyCredencials(company);

    let x = await this.auth(company).toPromise();

    return await this.httpClient
      .get<any[]>(
        this.companiesService.url +
          '/modules?token=' +
          x.token +
          '&returnType=json&modules=' +
          module
      )
      .toPromise();
  }

  async getClientInfoByEmail(company: string, email: string) {
    this.companiesService.setCompanyCredencials(company);

    let x = await this.auth(company).toPromise();

    return await this.httpClient
      .get<any[]>(
        this.companiesService.url +
          '/getclientbyemail?token=' +
          x.token +
          '&returnType=json&email_cliente=' +
          email
      )
      .toPromise();
  }

  async getRegionFromCRM(company: string) {
    this.companiesService.setCompanyCredencials(company);

    let x = await this.auth(company).toPromise();

    return await this.httpClient
      .get<any[]>(
        this.companiesService.url +
          '/getRegions?token=' +
          x.token +
          '&returnType=json&profiles[]=4&fields=nome,email,id_usuario,foto,telefone'
      )
      .toPromise();
  }

  async getDistrictFromCRM(company: string) {
    this.companiesService.setCompanyCredencials(company);

    let x = await this.auth(company).toPromise();

    return await this.httpClient
      .get<any[]>(
        this.companiesService.url +
          '/getDistricts?token=' +
          x.token +
          '&returnType=json&profiles[]=4&fields=nome,email,id_usuario,foto,telefone'
      )
      .toPromise();
  }
}
