import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxNumberSpinnerModule } from 'ngx-number-spinner';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { RegistrationModule } from './registration/registration.module';
import { SharedModule } from './shared.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/template/header/header.component';
import { StyleguideAppComponent } from './components/styleguide-app/styleguide-app.component';
import { StyleguideLoginComponent } from './components/styleguide-login/styleguide-login.component';
import { FooterComponent } from './components/template/footer/footer.component';
import { AvatarComponent } from './components/template/ui/avatar/avatar.component';
import { HomeComponent } from './views/home/home.component';
import { HelpComponent } from './views/help/help.component';
import { HomeLoggedComponent } from './views/home-logged/home-logged.component';
import { PasswordComponent } from './views/myaccount/password/password.component';
import { DataComponent } from './views/myaccount/data/data.component';
import { LoginComponent } from './views/myaccount/login/login.component';
import { ModalsComponent } from './components/modals/modals.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { ProductsModule } from './products/products.module'; // TODO: remover esse módulo. Usado somente para exibir modais para o QA
import { WherePipe } from './shared/pipes/where.pipe';
import { CompaniesModule } from './companies/companies.module'; // TODO: remover esse módulo. Usado somente para exibir modais para o QA

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StyleguideAppComponent,
    StyleguideLoginComponent,
    FooterComponent,
    AvatarComponent,
    HomeComponent,
    HelpComponent,
    HomeLoggedComponent,
    PasswordComponent,
    DataComponent,
    LoginComponent,
    ModalsComponent,
    WherePipe,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    Ng5SliderModule,
    NgxNumberSpinnerModule,
    RegistrationModule,
    CompaniesModule,
    ProductsModule,
    SharedModule,
    ScrollToModule.forRoot(),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    HttpClientModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    MatDialogModule,
  ],
  providers: [
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
