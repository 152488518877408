import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Company } from '../shared/interfaces/company.interface';
import { companiesLookupTable } from './companies.lookupTable';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  login: string;
  password: string;
  url: string;

  constructor(private httpClient: HttpClient) {}

  getCompanies(): Observable<Company[]> {
    return this.httpClient
      .get<Company[]>(environment.api_url + '/companies')
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getCompanyBySubdomain(subdomain: string): Observable<Company> {
    return this.httpClient
      .get<Company>(environment.api_url + '/companies?subdomain=' + subdomain)
      .pipe(map((res) => res[0]));
  }

  setCompanyCredencials(company: string): void {
    const companyCredencials = companiesLookupTable[company];

    if (companyCredencials) {
      this.login = companyCredencials.login;
      this.password = companyCredencials.password;
      this.url = companyCredencials.url;
    } else {
      console.error('Empresa não encontrada');
    }
  }
}
