import { environment } from '../../environments/environment';

export const companiesLookupTable = {
  ryazbek: {
    login: 'adm',
    password: environment.password_ryazbek,
    url: `https://ryazbek-parceria.hypnobox.com.br/api`,
  },
  gamaro: {
    login: 'fibra1parcerias',
    password: environment.password_fibra,
    url: `https://gamaro-parcerias.hypnobox.com.br/api`,
  },
  fibra: {
    login: 'fibra1parcerias',
    password: environment.password_fibra,
    url: `https://fibra-parcerias.hypnobox.com.br/api`,
  },
  curyconstrutora: {
    login: 'curyspparcerias',
    password: environment.password_cury,
    url: `https://curyconstrutora-parcerias.hypnobox.com.br/api`,
  },
  curyconstrutorarj: {
    login: 'curyrj_parcerias',
    password: environment.password_curyrj,
    url: `https://curyconstrutorarj-parcerias.hypnobox.com.br/api`,
  },
  skr: {
    login: 'skrparcerias',
    password: environment.password_skr,
    url: `https://skr-parcerias.hypnobox.com.br/api`,
  },
  sequoia: {
    login: 'adm_sequoia_parcerias',
    password: environment.password_sequoia,
    url: `https://sequoia-parcerias.hypnobox.com.br/api`,
  },
  exto: {
    login: 'extoparcerias',
    password: environment.password_exto,
    url: `https://extoparcerias.hypnobox.com.br/api`,
  },
  hypnoboxparcerias: {
    login: 'parcerias',
    password: environment.password_parcerias,
    url: `https://parcerias.hypnobox.com.br/api`,
  },
  'localhost:4200': {
    login: 'parcerias',
    password: environment.password_parcerias,
    url: `https://parcerias.hypnobox.com.br/api`,
  },
  start: {
    login: 'parcerias',
    password: environment.password_start,
    url: `https://start-parcerias.hypnobox.com.br/api`,
  },
  mitre: {
    login: 'adm_mitre_parcerias',
    password: environment.password_mitre,
    url: `https://mitre-parcerias.hypnobox.com.br/api`,
  },
  alfa: {
    login: 'parcerias',
    password: environment.password_alfa,
    url: `https://alfa-parcerias.hypnobox.com.br/api`,
  },
  steel: {
    login: 'parcerias',
    password: environment.password_steel,
    url: `https://steel-parcerias.hypnobox.com.br/api`,
  },
  aam: {
    login: 'aamparcerias',
    password: environment.password_aam,
    url: `https://aam-parcerias.hypnobox.com.br/api`,
  },
  paesegregori: {
    login: 'paesegregoriparcerias',
    password: environment.password_paesegregori,
    url: `https://paesegregori-parcerias.hypnobox.com.br/api`,
  },
  vendascanopus: {
    login: 'adm_canopus_parcerias',
    password: environment.password_canopus,
    url: `https://canopus-parcerias.hypnobox.com.br/api`,
  },
  fraiha: {
    login: 'fraihaparcerias',
    password: environment.password_fraiha,
    url: `https://fraiha-parcerias.hypnobox.com.br/api`,
  },
  cs: {
    login: 'adm_cs_parcerias',
    password: environment.password_cs,
    url: `https://cs-parcerias.hypnobox.com.br/api`,
  },
  wvmaldi: {
    login: 'parcerias',
    password: environment.password_wvmaldi,
    url: `https://wvmaldi-parcerias.hypnobox.com.br/api`,
  },
  altti: {
    login: 'alttiparcerias',
    password: environment.password_altti,
    url: `https://altti-parcerias.hypnobox.com.br/api`,
  },
  planik: {
    login: 'parcerias',
    password: environment.password_planik,
    url: `https://planik-parcerias.hypnobox.com.br/api`,
  },
  brasilincorp: {
    login: 'parcerias',
    password: environment.password_brasilincorp,
    url: `https://brasilincorp-parcerias.hypnobox.com.br/api`,
  },
};
